@tailwind base;
@tailwind components;
@tailwind utilities;

.relay-url-container {
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
  overflow: hidden;
}
.relay-url-container::before,
.relay-url-container::after {
  content: "";
  position: absolute;
  z-index: -1;
}

.relay-url-container::before {
  animation: 6s rotate-shine linear infinite;
  width: 100%;
  height: 1500%;
  background: var(--tile-border);
}

.relay-url-container::after {
  inset: 0;
  padding: 1px;
  background-clip: content-box;
}

@keyframes rotate-shine {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );
}

html.dark {
  --tile-start-rgb: 2, 13, 46;
  --tile-end-rgb: 2, 5, 19;
  --tile-border: conic-gradient(
    #ffffff80,
    #ffffff40,
    #ffffff30,
    #ffffff20,
    #ffffff10,
    #ffffff10,
    #ffffff80
  );
}

@keyframes fadeInOut {
  0%   {opacity: 0;}
  50%  {opacity: 1;}
  100% {opacity: 0;}
}

.fadeInOut-text {
  animation: fadeInOut 2s linear infinite;
}

/* Load spinner CSS from: https://loading.io/css/ */
/* Refactored to use relative positions and sizes */
.load-spinner {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.load-spinner div {
  position: absolute;
  width: 7.5%; /* 6px / 80px = 0.075 */
  height: 7.5%; /* 6px / 80px = 0.075 */
  /* background: #fff; */
  border-radius: 50%;
  animation: load-spinner 1.2s linear infinite;
}
.load-spinner div:nth-child(1) {
  animation-delay: 0s;
  top: 46.25%; /* 37px / 80px = 0.4625 */
  left: 82.5%; /* 66px / 80px = 0.825 */
}
.load-spinner div:nth-child(2) {
  animation-delay: -0.1s;
  top: 27.5%; /* 22px / 80px = 0.275 */
  left: 77.5%; /* 62px / 80px = 0.775 */
}
.load-spinner div:nth-child(3) {
  animation-delay: -0.2s;
  top: 13.75%; /* 11px / 80px = 0.1375 */
  left: 65%; /* 52px / 80px = 0.65 */
}
.load-spinner div:nth-child(4) {
  animation-delay: -0.3s;
  top: 8.75%; /* 7px / 80px = 0.0875 */
  left: 46.25%; /* 37px / 80px = 0.4625 */
}
.load-spinner div:nth-child(5) {
  animation-delay: -0.4s;
  top: 13.75%; /* 11px / 80px = 0.1375 */
  left: 27.5%; /* 22px / 80px = 0.275 */
}
.load-spinner div:nth-child(6) {
  animation-delay: -0.5s;
  top: 27.5%; /* 22px / 80px = 0.275 */
  left: 13.75%; /* 11px / 80px = 0.1375 */
}
.load-spinner div:nth-child(7) {
  animation-delay: -0.6s;
  top: 46.25%; /* 37px / 80px = 0.4625 */
  left: 8.75%; /* 7px / 80px = 0.0875 */
}
.load-spinner div:nth-child(8) {
  animation-delay: -0.7s;
  top: 65%; /* 52px / 80px = 0.65 */
  left: 13.75%; /* 11px / 80px = 0.1375 */
}
.load-spinner div:nth-child(9) {
  animation-delay: -0.8s;
  top: 77.5%; /* 62px / 80px = 0.775 */
  left: 27.5%; /* 22px / 80px = 0.275 */
}
.load-spinner div:nth-child(10) {
  animation-delay: -0.9s;
  top: 82.5%; /* 66px / 80px = 0.825 */
  left: 46.25%; /* 37px / 80px = 0.4625 */
}
.load-spinner div:nth-child(11) {
  animation-delay: -1s;
  top: 77.5%; /* 62px / 80px = 0.775 */
  left: 65%; /* 52px / 80px = 0.65 */
}
.load-spinner div:nth-child(12) {
  animation-delay: -1.1s;
  top: 65%; /* 52px / 80px = 0.65 */
  left: 77.5%; /* 62px / 80px = 0.775 */
}
@keyframes load-spinner {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

/* Overrides for React-Toastify https://fkhadra.github.io/react-toastify/how-to-style/ */
.Toastify__toast {
  background: none !important;
  padding: 0 !important;
}

.Toastify__toast-container {
  width: 100% !important;
  padding: 0 !important; 
}

.Toastify__toast-body {
  padding: 0 !important; 
}

/* media query for md screen */
@media (min-width: 768px) {
  .Toastify__toast-container {
    max-width: 42rem !important; /* 2xl in Tailwind CSS */
  }
}